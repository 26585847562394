import React from 'react'
import { Preview } from 'react-bricks/frontend'
import Seo from '../components/seo'

const preview = () => {
  return (
    <>
      <Seo title="Preview" />
      <Preview />
    </>
  )
}

export default preview
